import React from "react";

const Tranportation = () => {
    return (
        <>
            <div style={{ backgroundColor: "black" }}>
                <img src="../picture/servtop.png" style={{ marginTop: "20px" }} alt="tranporations-images"></img>
                <div className="" style={{ padding: '1px' }}>
                    <img src="../picture/serv.png" style={{ marginTop: "15px" }} alt="tranporations-images"></img>
                </div>
            </div>
        </>
    );
}
export default Tranportation;   